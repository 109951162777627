
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import { toTitleCase } from "@evercam/shared/utils"

export default {
  components: {
    CopyTableToClipboard,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {},
      sortBy: "total",
      sortDesc: true,
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = this.$refs.eventsStatsTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
  },
  methods: {
    toTitleCase,
  },
}
